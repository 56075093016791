import React, { useState, useEffect } from "react";
import Logo from "../../Assets/mirrorLogo.png";
import Classes from "./Footer.module.css";
import Facebook from "../../Assets/Facebook.png";
import Instagram from "../../Assets/instagram.png";
import LinkedIn from "../../Assets/linkedin.png";
import Image1 from "../../Assets/1.png";
import Image2 from "../../Assets/2.png";
import Image3 from "../../Assets/3.png";
import Image4 from "../../Assets/4.png";
import Image5 from "../../Assets/5.png";
import Image6 from "../../Assets/6.png";
import { SiMinutemailer } from "react-icons/si";
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Urls from "../../Urls";

function Footer() {
  const [catgSet, setCatgSet] = useState([]);
  const [occation, setOccation] = useState([]);
  const token = localStorage.getItem("swaToken");
  const history = useHistory();

  useEffect(() => {
    axios
      .get(Urls.filter + "?category=&tag=")
      .then((response1) => {
        setCatgSet(response1.data.results.data.category);
        setOccation(response1.data.results.data.tags);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const AboutUs = () => {
    history.push("about_us");
  };
  const catSelHandler = (id) => {
    if (history.location.pathname !== "/new_arrivel") {
      history.push({ pathname: "/new_arrivel", state: { data: id } });
    }
  };
  const occationSelHandler = (id) => {
    if (history.location.pathname !== "/new_arrivel") {
      history.push({
        pathname: "/new_arrivel",
        state: { data: "occation", octnId: id },
      });
    }
  };
  return (
    <div>
      <div className={Classes.Footer}>
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <img
                className={Classes.Logo}
                src={Logo}
                alt=""
                style={{ maxWidth: "180px" }}
              />
              <p className={Classes.LogoText}>
                Concept of Mirror Diamonds came into being from CAPESTONE
                Ventures Pvt Ltd, a leading name in wholesale diamond jewellers
                market, that does business with prominent retail jewellers. Many
                retail jewellers who deal only in gold jewellery are reluctant
                to add diamond jewellery to their stock due to certain factors
              </p>
            </div>
            <div className="col-md-6">
              <div className="container">
                <div className="row">
                  <div className="col-md-4">
                    <h1 className={Classes.Title}>Genral info</h1>
                    <div className={Classes.Links}>
                      <Link to="/faq">
                        <p className={Classes.Links}>FAQ</p>
                      </Link>
                      <p className={Classes.Links}> Contact us</p>
                      <p
                        className={Classes.Links}
                        onClick={AboutUs}
                        style={{ cursor: "pointer" }}
                      >
                        About us
                      </p>
                      <Link to="/privacy_policy">
                        <p className={Classes.Links}>Privacy policy</p>
                      </Link>
                      <Link to="/Return_policy_page">
                        <p className={Classes.Links}>Return policy</p>
                      </Link>
                      <Link to="/terms_condition">
                        <p className={Classes.Links}>Terms & conditions</p>
                      </Link>
                      <p className={Classes.Links}>Outlets</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <h1 className={Classes.Title}>Quick links</h1>
                    {catgSet.slice(0, 15).map((item, index) => {
                      return (
                        <p
                          className={Classes.Links}
                          onClick={() => catSelHandler(item.id)}
                          style={{ color: "#ffff", cursor: "pointer" }}
                          key={index}
                        >
                          {item.category}{" "}
                        </p>
                      );
                    })}
                  </div>
                  <div className="col-md-4">
                    <div className={Classes.Link}>
                      {occation.map((item, index) => {
                        return (
                          <p
                            className={Classes.Links}
                            style={{ color: "#ffff", cursor: "pointer" }}
                            key={index}
                            onClick={() => occationSelHandler(item.id)}
                          >
                            {item.tag}
                          </p>
                        );
                      })}
                    </div>
                  
                  </div>
                
                </div>
                <div className="col-md-4">
                      <h1 className={Classes.Title}>Franchise Login's</h1>
                      <a href="http://franchise.mirrordiamonds.com/Auth/Login?ReturnUrl=%2f">
                      <p
                        className={Classes.Links}
                        style={{ color: "#ffff", cursor: "pointer" }}
                        
                        > Mirror Diamonds</p>
                        </a>
                        <a href="https://oms.mirrordiamonds.com/">
                       <p
                        className={Classes.Links}
                        style={{ color: "#ffff", cursor: "pointer" }}
                        
                        > Mirror OMS</p>
                        </a>
                        <a href="https://swarepairtest.zinfog.com/login/?next=/">
                       <p
                        className={Classes.Links}
                        style={{ color: "#ffff", cursor: "pointer" }}
                        
                        >Mirror Repair</p>
                        </a>
                    </div>
              </div>
              <p></p>
            </div>
            <div className="col-md-3">
              <p className={Classes.Title}>Mirror contact info</p>
              <div className={Classes.Address}>
                <SiMinutemailer
                  size={35}
                  color="#99C7CD"
                  className={Classes.AddressText}
                />
                <p className={`${Classes.Links} ${Classes.AddressText}`}>
                  Ground Floor, 7/688E, Al Wahad, Chenguvetty, Kerala, 676501
                </p>
              </div>
              <div className={Classes.Address}>
                <FaPhoneAlt
                  color="#99C7CD"
                  size={18}
                  className={Classes.AddressText}
                />
                <p className={`${Classes.Links} ${Classes.AddressText}`}>
                  Toll Free Number : 1800 257 8600
                </p>
              </div>
              <div className={Classes.Address}>
                <HiOutlineMail
                  color="#99C7CD"
                  size={20}
                  className={Classes.AddressText}
                />
                <p className={`${Classes.Links} ${Classes.AddressText}`}>
                  info@mirrordiamonds.com
                </p>
              </div>
              <p className={`${Classes.Title} ${Classes.FollowUs}`}>
                Follow us on
              </p>
              <div className={Classes.Icons}>
                <img src={Facebook} alt="" />
                <img src={LinkedIn} alt="" />
                <img src={Instagram} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={Classes.FooterDown}>
        <div className="container">
          <div className="row">
            <div className="col-md-4" style={{ paddingLeft: "0px" }}>
              <p className={Classes.FooterDownText}>
                2023 Mirror Diamonds | All rights reserved
              </p>
            </div>
            <div className={`${"col-md-4"} ${Classes.FootIconImg}`}>
              <img className={Classes.FooterIcons} src={Image1} alt="" />
              <img className={Classes.FooterIcons} src={Image2} alt="" />
              <img className={Classes.FooterIcons} src={Image3} alt="" />
              <img className={Classes.FooterIcons} src={Image4} alt="" />
              <img className={Classes.FooterIcons} src={Image5} alt="" />
              <img className={Classes.FooterIcons} src={Image6} alt="" />
            </div>
            <div className="col-md-4">
              <p className={Classes.FooterDownTextR}>
                Site Designed & developed by zinfog codelabs
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;

{
  /* <div className={Classes.FooterDown}>
<p className={Classes.FooterDownText}>
    2022 SWA Diamonds | All rights reserved
</p>
<div >
    <img className={Classes.FooterIcons} src={Image1} alt='' />
    <img className={Classes.FooterIcons} src={Image2} alt='' />
    <img className={Classes.FooterIcons} src={Image3} alt='' />
    <img className={Classes.FooterIcons} src={Image4} alt='' />
    <img className={Classes.FooterIcons} src={Image5} alt='' />
    <img className={Classes.FooterIcons} src={Image6} alt='' />
</div>
<p className={Classes.FooterDownText}>
    Site Designed & developed by zinfog codelabs
</p>
</div> */
}
