// // t server
// export const server = "https://mirrordiamonds.com/";
export const server = "https://mirroradmin.mirrordiamonds.com/";
// export const server = "https://mirrordiamonds.zinfog.in/";
// export const server = "https://swaprdntest.zinfog.com/";
// export const server = 'https://swaprdntest.zinfog.com/'
//home page api
export const home = server + "ecom/home/";
//add wishlist
export const addWishList = server + "ecom/wishlist/";
//product details
export const productDet = server + "ecom/products/";

//register
export const register = server + "ecom/register/";
//sent otp
export const sentOtp = server + "api/send-otp/";
//verify otp
export const verifyOTP = server + "api/verify-otp/";
//login
export const Login = server + "api/login/";
//wishlist
export const wishlist = server + "ecom/wishlist/";
//product list
export const productList = server + "ecom/products";
//filter
export const filter = server + "ecom/components/";
//add to cart
export const cart = server + "ecom/cartitems/";
//promo code
export const promoCode = server + "ecom/applypromo/";
//ADDRESS
export const address = server + "ecom/addresses/all/";
//add addresss
export const addAdress = server + "ecom/addresses/";
//place order
export const checkout = server + "ecom/checkout/";
//create review
export const review = server + "ecom/products/review/";
//my order
export const myOrder = server + "ecom/orders/";
//buy now
export const buyNow = server + "ecom/buynow/";
//order pay success
export const paySuces = server + "ecom/payment-res/";
//pincode token
//search  suggetion
export const suggestion = server + "ecom/recommendations/?keyword=";
//add recent
export const addRecent = server + "ecom/recent/";
//update password
export const updatePas = server + "api/update/";
//pincode check
export const pincodeCheck = server + "ecom/pincode_check/?pincode=";
//track
export const track = server + "ecom/product-tracking/";

export const pinCodeToken =
  "https://bvcmars.com/RestService/OrderUploadService.svc/GenerateAuthenticationToken";
//pincode
export const pinCode =
  "https://bvcmars.com/bvc_staging/RestService/PincodeMasterService.svc/GetPincodeData";

//track order
export const trackOrder =
  "https://bvcmars.com/RestService/TrackingService.svc/GetDocketTrackingDetails";
