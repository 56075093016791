export const countryList =  [
    "QA",
    "BD",
    "BE",
    "BF",
    "BG",
    "BA",
    "BB",
    "WF",
    "BL",
    "BM",
    "BN",
    "BO",
    "BH",
    "BI",
    "BJ",
    "BT",
    "JM",
    "BV",
    "BW",
    "WS",
    "BQ",
    "BR",
    "BS",
    "JE",
    "BY",
    "BZ",
    "RU",
    "RW",
    "RS",
    "TL",
    "RE",
    "TM",
    "TJ",
    "RO",
    "TK",
    "GW",
    "GU",
    "GT",
    "GS",
    "GR",
    "GQ",
    "GP",
    "JP",
    "GY",
    "GG",
    "GF",
    "GE",
    "GD",
    "GB",
    "GA",
    "SV",
    "GN",
    "GM",
    "GL",
    "GI",
    "GH",
    "OM",
    "TN",
    "JO",
    "HR",
    "HT",
    "HU",
    "HK",
    "HN",
    "HM",
    "VE",
    "PR",
    "PS",
    "PW",
    "PT",
    "SJ",
    "PY",
    "IQ",
    "PA",
    "PF",
    "PG",
    "PE",
    "PK",
    "PH",
    "PN",
    "PL",
    "PM",
    "ZM",
    "EH",
    "EE",
    "EG",
    "ZA",
    "EC",
    "IT",
    "VN",
    "SB",
    "ET",
    "SO",
    "ZW",
    "SA",
    "ES",
    "ER",
    "ME",
    "MD",
    "MG",
    "MF",
    "MA",
    "MC",
    "UZ",
    "MM",
    "ML",
    "MO",
    "MN",
    "MH",
    "MK",
    "MU",
    "MT",
    "MW",
    "MV",
    "MQ",
    "MP",
    "MS",
    "MR",
    "IM",
    "UG",
    "TZ",
    "MY",
    "MX",
    "IL",
    "FR",
    "IO",
    "SH",
    "FI",
    "FJ",
    "FK",
    "FM",
    "FO",
    "NI",
    "NL",
    "NO",
    "NA",
    "VU",
    "NC",
    "NE",
    "NF",
    "NG",
    "NZ",
    "NP",
    "NR",
    "NU",
    "CK",
    "XK",
    "CI",
    "CH",
    "CO",
    "CN",
    "CM",
    "CL",
    "CC",
    "CA",
    "CG",
    "CF",
    "CD",
    "CZ",
    "CY",
    "CX",
    "CR",
    "CW",
    "CV",
    "CU",
    "SZ",
    "SY",
    "SX",
    "KG",
    "KE",
    "SS",
    "SR",
    "KI",
    "KH",
    "KN",
    "KM",
    "ST",
    "SK",
    "KR",
    "SI",
    "KP",
    "KW",
    "SN",
    "SM",
    "SL",
    "SC",
    "KZ",
    "KY",
    "SG",
    "SE",
    "SD",
    "DO",
    "DM",
    "DJ",
    "DK",
    "VG",
    "DE",
    "YE",
    "DZ",
    "US",
    "UY",
    "YT",
    "UM",
    "LB",
    "LC",
    "LA",
    "TV",
    "TW",
    "TT",
    "TR",
    "LK",
    "LI",
    "LV",
    "TO",
    "LT",
    "LU",
    "LR",
    "LS",
    "TH",
    "TF",
    "TG",
    "TD",
    "TC",
    "LY",
    "VA",
    "VC",
    "AE",
    "AD",
    "AG",
    "AF",
    "AI",
    "VI",
    "IS",
    "IR",
    "AM",
    "AL",
    "AO",
    "AQ",
    "AS",
    "AR",
    "AU",
    "AT",
    "AW",
    "IN",
    "AX",
    "AZ",
    "IE",
    "ID",
    "UA",
    "MZ"]
export const countryCode = {
    QA: "QA +974",
    BD: "BD +880",
    BE: "BE +32",
    BF: "BF +226",
    BG: "BG +359",
    BA: "BA +387",
    BB: "BB +1-246",
    WF: "WF +681",
    BL: "BL +590",
    BM: "BM +1-441",
    BN: "BN +673",
    BO: "BO +591",
    BH: "BH +973",
    BI: "BI +257",
    BJ: "BJ +229",
    BT: "BT +975",
    JM: "JM +1-876",
    BV: "",
    BW: "BW +267",
    WS: "WS +685",
    BQ: "BQ +599",
    BR: "BR +55",
    BS: "BS +1-242",
    JE: "JE +44-1534",
    BY: "BY +375",
    BZ: "BZ +501",
    RU: "RU +7",
    RW: "RW +250",
    RS: "RS +381",
    TL: "TL +670",
    RE: "RE +262",
    TM: "TM +993",
    TJ: "TJ +992",
    RO: "RO +40",
    TK: "TK +690",
    GW: "GW +245",
    GU: "GU +1-671",
    GT: "GT +502",
    GS: "",
    GR: "GR +30",
    GQ: "GQ +240",
    GP: "GP +590",
    JP: "JP +81",
    GY: "GY +592",
    GG: "GG +44-1481",
    GF: "GF +594",
    GE: "GE +995",
    GD: "GD +1-473",
    GB: "GB +44",
    GA: "GA +241",
    SV: "SV +503",
    GN: "GN +224",
    GM: "GM +220",
    GL: "GL +299",
    GI: "GI +350",
    GH: "GH +233",
    OM: "OM +968",
    TN: "TN +216",
    JO: "JO +962",
    HR: "HR +385",
    HT: "HT +509",
    HU: "HU +36",
    HK: "HK +852",
    HN: "HN +504",
    HM: " ",
    VE: "VE +58",
    PR: "PR +1-787 and +1-939",
    PS: "PS +970",
    PW: "PW +680",
    PT: "PT +351",
    SJ: "SJ +47",
    PY: "PY +595",
    IQ: "IQ +964",
    PA: "PA +507",
    PF: "PF +689",
    PG: "PG +675",
    PE: "PE +51",
    PK: "PK +92",
    PH: "PH +63",
    PN: "PN +870",
    PL: "PL +48",
    PM: "PM +508",
    ZM: "ZM +260",
    EH: "EH +212",
    EE: "EE +372",
    EG: "EG +20",
    ZA: "ZA +27",
    EC: "EC +593",
    IT: "IT +39",
    VN: "VN +84",
    SB: "SB +677",
    ET: "ET +251",
    SO: "SO +252",
    ZW: "ZW +263",
    SA: "SA +966",
    ES: "ES +34",
    ER: "ER +291",
    ME: "ME +382",
    MD: "MD +373",
    MG: "MG +261",
    MF: "MF +590",
    MA: "MA +212",
    MC: "MC +377",
    UZ: "UZ +998",
    MM: "MM +95",
    ML: "ML +223",
    MO: "MO +853",
    MN: "MN +976",
    MH: "MH +692",
    MK: "MK +389",
    MU: "MU +230",
    MT: "MT +356",
    MW: "MW +265",
    MV: "MV +960",
    MQ: "MQ +596",
    MP: "MP +1-670",
    MS: "MS +1-664",
    MR: "MR +222",
    IM: "IM +44-1624",
    UG: "UG +256",
    TZ: "TZ +255",
    MY: "MY +60",
    MX: "MX +52",
    IL: "IL +972",
    FR: "FR +33",
    IO: "IO +246",
    SH: "SH +290",
    FI: "FI +358",
    FJ: "FJ +679",
    FK: "FK +500",
    FM: "FM +691",
    FO: "FO +298",
    NI: "NI +505",
    NL: "NL +31",
    NO: "NO +47",
    NA: "NA +264",
    VU: "VU +678",
    NC: "NC +687",
    NE: "NE +227",
    NF: "NF +672",
    NG: "NG +234",
    NZ: "NZ +64",
    NP: "NP +977",
    NR: "NR +674",
    NU: "NU +683",
    CK: "CK +682",
    XK: "",
    CI: "CI +225",
    CH: "CH +41",
    CO: "CO +57",
    CN: "CN +86",
    CM: "CM +237",
    CL: "CL +56",
    CC: "CC +61",
    CA: "CA +1",
    CG: "CG +242",
    CF: "CF +236",
    CD: "CD +243",
    CZ: "CZ +420",
    CY: "CY +357",
    CX: "CX +61",
    CR: "CR +506",
    CW: "CW +599",
    CV: "CV +238",
    CU: "CU +53",
    SZ: "SZ +268",
    SY: "SY +963",
    SX: "SX +599",
    KG: "KG +996",
    KE: "KE +254",
    SS: "SS +211",
    SR: "SR +597",
    KI: "KI +686",
    KH: "KH +855",
    KN: "KN +1-869",
    KM: "KM +269",
    ST: "ST +239",
    SK: "SK +421",
    KR: "KR +82",
    SI: "SI +386",
    KP: "KP +850",
    KW: "KW +965",
    SN: "SN +221",
    SM: "SM +378",
    SL: "SL +232",
    SC: "SC +248",
    KZ: "KZ +7",
    KY: "KY +1-345",
    SG: "SG +65",
    SE: "SE +46",
    SD: "SD +249",
    DO: "DO +1-809 and 1-829",
    DM: "DM +1-767",
    DJ: "DJ +253",
    DK: "DK +45",
    VG: "VG +1-284",
    DE: "DE +49",
    YE: "YE +967",
    DZ: "DZ +213",
    US: "US +1",
    UY: "UY +598",
    YT: "YT +262",
    UM: "UM +1",
    LB: "LB +961",
    LC: "LC +1-758",
    LA: "LA +856",
    TV: "TV +688",
    TW: "TW +886",
    TT: "TT +1-868",
    TR: "TR 90",
    LK: "LK +94",
    LI: "LI +423",
    LV: "LV +371",
    TO: "TO +676",
    LT: "LT +370",
    LU: "LU +352",
    LR: "LR +231",
    LS: "LS +266",
    TH: "TH +66",
    TF: "",
    TG: "TG +228",
    TD: "TD +235",
    TC: "TC +1-649",
    LY: "LY +218",
    VA: "VA +379",
    VC: "VC +1-784",
    AE: "AE +971",
    AD: "AD +376",
    AG: "AG +1-268",
    AF: "AF +93",
    AI: "AI +1-264",
    VI: "VI +1-340",
    IS: "IS +354",
    IR: "IR +98",
    AM: "AM +374",
    AL: "AL +355",
    AO: "AO +244",
    AQ: "",
    AS: "AS +1-684",
    AR: "AR +54",
    AU: "AU +61",
    AT: "AT +43",
    AW: "AW +297",
    IN: "IN +91",
    AX: "AX +358-18",
    AZ: "AZ +994",
    IE: "IE +353",
    ID: "ID +62",
    UA: "UA +380",
   
    MZ: "+258"
  } 
  export const arrayCountryList = [
    {name:'QA',code:"974"},
    {name:'BD',code:"880"},
    {name:'BE',code:"32"},
    {name:'BF',code:"226"},
    {name:'BG',code:"359"},
    {name:'BA',code:"387"},
    {name:'BB',code:"+1-246"},
    {name:'WF',code:"681"},
    {name:'BL',code:"590"},
    {name:'BM',code:"+1-441"},
    {name:'BN',code:"673"},
    {name:'BO',code:"591"},
    {name:'BH',code:"973"},
    {name:'BI',code:"257"},
    {name:'BJ',code:"229"},
    {name:'BT',code:"975"},
    {name:'JM',code:"+1-876"},
    {name:'BV',code:""},
    {name:'BW',code:"267"},
    {name:'WS',code:"685"},
    {name:'BQ',code:"599"},
    {name:'BR',code:"55"},
    {name:'BS',code:"+1-242"},
    {name:'JE',code:"+44-1534"},
    {name:'BY',code:"375"},
    {name:'BZ',code:"501"},
    {name:'RU',code:"7"},
    {name:'RW',code:"250"},
    {name:'RS',code:"381"},
    {name:'TL',code:"670"},
    {name:'RE',code:"262"},
    {name:'TM',code:"993"},
    {name:'TJ',code:"992"},
    {name:'RO',code:"40"},
    {name:'TK',code:"690"},
    {name:'GW',code:"245"},
    {name:'GU',code:"+1-671"},
    {name:'GT',code:"502"},
    {name:'GS',code:""},
    {name:'GR',code:"30"},
    {name:'GQ',code:"240"},
    {name:'GP',code:"590"},
    {name:'JP',code:"81"},
    {name:'GY',code:"592"},
    {name:'GG',code:"+44-1481"},
    {name:'GF',code:"594"},
    {name:'GE',code:"995"},
    {name:'GD',code:"+1-473"},
    {name:'GB',code:"44"},
    {name:'GA',code:"241"},
    {name:'SV',code:"503"},
    {name:'GN',code:"224"},
    {name:'GM',code:"220"},
    {name:'GL',code:"299"},
    {name:'GI',code:"350"},
    {name:'GH',code:"233"},
    {name:'OM',code:"968"},
    {name:'TN',code:"216"},
    {name:'JO',code:"962"},
    {name:'HR',code:"385"},
    {name:'HT',code:"509"},
    {name:'HU',code:"36"},
    {name:'HK',code:"852"},
    {name:'HN',code:"504"},
    {name:'HM',code:" "},
    {name:'VE',code:"58"},
    {name:'PR',code:"+1-787 and 1-939"},
    {name:'PS',code:"970"},
    {name:'PW',code:"680"},
    {name:'PT',code:"351"},
    {name:'SJ',code:"47"},
    {name:'PY',code:"595"},
    {name:'IQ',code:"964"},
    {name:'PA',code:"507"},
    {name:'PF',code:"689"},
    {name:'PG',code:"675"},
    {name:'PE',code:"51"},
    {name:'PK',code:"92"},
    {name:'PH',code:"63"},
    {name:'PN',code:"870"},
    {name:'PL',code:"48"},
    {name:'PM',code:"508"},
    {name:'ZM',code:"260"},
    {name:'EH',code:"212"},
    {name:'EE',code:"372"},
    {name:'EG',code:"20"},
    {name:'ZA',code:"27"},
    {name:'EC',code:"593"},
    {name:'IT',code:"39"},
    {name:'VN',code:"84"},
    {name:'SB',code:"677"},
    {name:'ET',code:"251"},
    {name:'SO',code:"252"},
    {name:'ZW',code:"263"},
    {name:'SA',code:"966"},
    {name:'ES',code:"34"},
    {name:'ER',code:"291"},
    {name:'ME',code:"382"},
    {name:'MD',code:"373"},
    {name:'MG',code:"261"},
     {name:'MF',code:"590"},
     {name:'MA',code:"212"},
     {name:'MC',code:"377"},
     {name:'UZ',code:"998"},
     {name:'MM',code:"95"},
     {name:'ML',code:"223"},
     {name:'MO',code:"853"},
     {name:'MN',code:"976"},
     {name:'MH',code:"692"},
     {name:'MK',code:"389"},
     {name:'MU',code:"230"},
     {name:'MT',code:"356"},
     {name:'MW',code:"265"},
     {name:'MV',code:"960"},
     {name:'MQ',code:"596"},
     {name:'MP',code:"+1-670"},
     {name:'MS',code:"+1-664"},
     {name:'MR',code:"222"},
     {name:'IM',code:"+44-1624"},
     {name:'UG',code:"256"},
     {name:'TZ',code:"255"},
     {name:'MY',code:"60"},
     {name:'MX',code:"52"},
     {name:'IL',code:"972"},
     {name:'FR',code:"33"},
     {name:'IO',code:"246"},
     {name:'SH',code:"290"},
     {name:'FI',code:"358"},
     {name:'FJ',code:"679"},
     {name:'FK',code:"500"},
     {name:'FM',code:"691"},
     {name:'FO',code:"298"},
     {name:'NI',code:"505"},
     {name:'NL',code:"31"},
     {name:'NO',code:"47"},
     {name:'NA',code:"264"},
     {name:'VU',code:"678"},
     {name:'NC',code:"687"},
     {name:'NE',code:"227"},
     {name:'NF',code:"672"},
     {name:'NG',code:"234"},
     {name:'NZ',code:"64"},
     {name:'NP',code:"977"},
     {name:'NR',code:"674"},
     {name:'NU',code:"683"},
     {name:'CK',code:"682"},
     {name:'XK',code:""},
     {name:'CI',code:"225"},
     {name:'CH',code:"41"},
     {name:'CO',code:"57"},
     {name:'CN',code:"86"},
     {name:'CM',code:"237"},
     {name:'CL',code:"56"},
     {name:'CC',code:"61"},
     {name:'CA',code:"1"},
     {name:'CG',code:"242"},
     {name:'CF',code:"236"},
     {name:'CD',code:"243"},
     {name:'CZ',code:"420"},
     {name:'CY',code:"357"},
     {name:'CX',code:"61"},
     {name:'CR',code:"506"},
     {name:'CW',code:"599"},
     {name:'CV',code:"238"},
     {name:'CU',code:"53"},
     {name:'SZ',code:"268"},
     {name:'SY',code:"963"},
     {name:'SX',code:"599"},
     {name:'KG',code:"996"},
     {name:'KE',code:"254"},
     {name:'SS',code:"211"},
     {name:'SR',code:"597"},
     {name:'KI',code:"686"},
     {name:'KH',code:"855"},
     {name:'KN',code:"+1-869"},
     {name:'KM',code:"269"},
     {name:'ST',code:"239"},
     {name:'SK',code:"421"},
     {name:'KR',code:"82"},
     {name:'SI',code:"386"},
     {name:'KP',code:"850"},
     {name:'KW',code:"965"},
     {name:'SN',code:"221"},
     {name:'SM',code:"378"},
     {name:'SL',code:"232"},
     {name:'SC',code:"248"},
     {name:'KZ',code:"7"},
     {name:'KY',code:"+1-345"},
     {name:'SG',code:"65"},
     {name:'SE',code:"46"},
     {name:'SD',code:"249"},
     {name:'DO',code:"+1-809 and 1-829"},
     {name:'DM',code:"+1-767"},
     {name:'DJ',code:"253"},
     {name:'DK',code:"45"},
     {name:'VG',code:"+1-284"},
     {name:'DE',code:"49"},
     {name:'YE',code:"967"},
     {name:'DZ',code:"213"},
     {name:'US',code:"1"},
     {name:'UY',code:"598"},
     {name:'YT',code:"262"},
     {name:'UM',code:"1"},
     {name:'LB',code:"961"},
     {name:'LC',code:"+1-758"},
     {name:'LA',code:"856"},
     {name:'TV',code:"688"},
     {name:'TW',code:"886"},
     {name:'TT',code:"+1-868"},
     {name:'TR',code:"90"},
     {name:'LK',code:"94"},
     {name:'LI',code:"423"},
     {name:'LV',code:"371"},
     {name:'TO',code:"676"},
     {name:'LT',code:"370"},
     {name:'LU',code:"352"},
     {name:'LR',code:"231"},
     {name:'LS',code:"266"},
     {name:'TH',code:"66"},
     {name:'TF',code:""},
     {name:'TG',code:"228"},
     {name:'TD',code:"235"},
     {name:'TC',code:"+1-649"},
     {name:'LY',code:"218"},
     {name:'VA',code:"379"},
     {name:'VC',code:"+1-784"},
     {name:'AE',code:"971"},
     {name:'AD',code:"376"},
     {name:'AG',code:"+1-268"},
     {name:'AF',code:"93"},
     {name:'AI',code:"+1-264"},
     {name:'VI',code:"+1-340"},
     {name:'IS',code:"354"},
     {name:'IR',code:"98"},
     {name:'AM',code:"374"},
     {name:'AL',code:"355"},
     {name:'AO',code:"244"},
     {name:'AQ',code:""},
     {name:'AS',code:"+1-684"},
     {name:'AR',code:"54"},
     {name:'AU',code:"61"},
     {name:'AT',code:"43"},
     {name:'AW',code:"297"},
     {name:'IN',code:"91"},
     {name:'AX',code:"+358-18"},
     {name:'AZ',code:"994"},
     {name:'IE',code:"353"},
     {name:'ID',code:"62"},
     {name:'UA',code:"380"},
     {name:'MZ',code:"258"}
]
  